import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// import DashboardNavSVG from "../../assets/svgs/DashboardNav.svg";
import DataInputNavSVG from "../../assets/svgs/data-input-nav.svg";
import AddAccount from "../accounts-list/add-account/AddAccount";
import TempLogo from "../../assets/svgs/temp-logo.svg";
import styles from "./Header.module.scss";
import NavWrpr from "./nav/NavWrpr";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import AccountSelection from "./AccountSelection";
import FacilitySelection from "./FacilitySelection";
import OverallSubSvg from "../../assets/svgs/OverallSubSvg.svg";
import HospitalSubSvg from "../../assets/svgs/HospitalSubSbg.svg";
import CommunityTransferSubSvg from "../../assets/svgs/CommunityTrasnferSubSvg.svg";
import DeceasedSubSvg from "../../assets/svgs/DeceasedSubSvg.svg";
import AdmissionsSubSvg from "../../assets/svgs/AdmissionsSubSvg.svg";
// import QuickGlanceSubSvg from "../../assets/svgs/QuickGlanceSubSvg.svg";
import AddNewSvg from "../../assets/svgs/AddNewSvg.svg";
import classNames from "classnames";
import ExpandArrowSvg from "../../assets/svgs/ExpandArrow.svg";
import { AUTH_ROLES } from "../../types/auth.type";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { PAGE_TYPE } from "../../types/pages.type";
import Logout from "@mui/icons-material/Logout";
import { getPercentageAgainstAll } from "../../services/filter.service";
import { setCustomePercentages } from "../../store/reducers/common.slice";
import { signOut } from "../../utilis/signout";
import {
  setAdmissionCards,
  setCommunityTransferCards,
  setDeceasedCards,
  setHospitalCards,
  setOverallCards,
} from "../../store/reducers/customCard.slice";
import { getCustomeCardFilters } from "../../services/custom-card.service";
import useSetParamToURL from "../hooks/useSetParamToURL";


const EXPAND_WIDTH = "280px";
const NOT_EXPAND_WIDTH = "79px";
const LAPTOP_RESOLUTION = 1536;

const Header = (props) => {
  const dispatch = useDispatch();
  const userOptionRef = useRef();
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [expandWidth, setExpandWidth] = useState(EXPAND_WIDTH);
  const selectedFacilities = useSelector(
    (state) => state.activeFacilities?.facilities
  );
  const roles = {
    superAdmin: "Super admin",
    admin: "Admin",
    user: "User",
  };
  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();
  const { auth, account, permission } = useSelector(
    ({ auth, account, permission }) => ({
      auth,
      account,
      permission,
    })
  );
  const { selectedAccount } = useSelector((state) => state.common);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const updateMenuState = () => {
      const root = document.querySelector(":root");
      if (window.innerWidth > LAPTOP_RESOLUTION) {
        root.style.setProperty("--sidebar-width", EXPAND_WIDTH);
        setExpandWidth(EXPAND_WIDTH);
        setExpanded(true);
      } else {
        root.style.setProperty("--sidebar-width", NOT_EXPAND_WIDTH);
        setExpanded(false);
        setExpandWidth(NOT_EXPAND_WIDTH);
      }
    };
    // Set the initial state
    updateMenuState();
    // Add event listener to update state on resize
    window.addEventListener('resize', updateMenuState);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateMenuState);
  }, []);

  const checkMenuPermission = useCallback(
    (roles) => {
      const { role = null } = auth;
      if (role && role.slug) {
        if (_.includes(roles, role.slug)) {
          return true;
        }
      }
      return false;
    },
    [auth]
  );

  const handleSignOut = () => signOut(navigate);
  const { setPreviousParams } = useSetParamToURL();
  const suMenuItem = useMemo(() => {
    const dashboardAccess =
      selectedAccount &&
        selectedAccount.dashboardAccess &&
        selectedAccount.dashboardAccess.length > 0
        ? selectedAccount.dashboardAccess
        : [];
    const items = [];

    const isActive = dashboardAccess.length === 1 ? true : false;

    if (_.includes(dashboardAccess, PAGE_TYPE.HOSPITAL)) {
      items.push({
        label: "Hospital",
        isActive,
        to: setPreviousParams("dashboard/hospital"),
        icon: <HospitalSubSvg />,
        replace: true
      });
    }

    if (_.includes(dashboardAccess, PAGE_TYPE.COMMUNITY_TRANSFER)) {
      items.push({
        label: "Community Transfer",
        to: setPreviousParams("dashboard/community-transfer"),
        icon: <CommunityTransferSubSvg />,
      });
    }

    if (_.includes(dashboardAccess, PAGE_TYPE.DECEASED)) {
      items.push({
        label: "Deceased",
        to: setPreviousParams("dashboard/deceased"),
        icon: <DeceasedSubSvg />,
      });
    }

    if (_.includes(dashboardAccess, PAGE_TYPE.ADMISSION)) {
      items.push({
        label: "Admissions",
        to: setPreviousParams("dashboard/admissions"),
        icon: <AdmissionsSubSvg />,
      });
    }
    if (_.includes(dashboardAccess, PAGE_TYPE.OVERALL)) {
      items.push({
        label: "Overall",
        isActive: !isActive,
        to: setPreviousParams("dashboard/overall"),
        icon: <OverallSubSvg />,
      });
    }

    return items;
  }, [selectedAccount, setPreviousParams]);

  const getAllPercentageAgainst = () => {
    getPercentageAgainstAll().then(async (res) => {
      if (res.length > 0) {
        let resUpdated = res.map((item, index) => {
          return { ...item, index };
        });
        dispatch(setCustomePercentages(resUpdated));
      } else {
        dispatch(setCustomePercentages([]));
      }
    });
  };

  const getAllCustomCardFilters = () => {
    getCustomeCardFilters().then(async (res) => {
      if (res.length > 0) {
        const deceasedCards = _.find(res, { page: PAGE_TYPE.DECEASED });
        const coTransfersCards = _.find(res, {
          page: PAGE_TYPE.COMMUNITY_TRANSFER,
        });
        const hospitalCards = _.find(res, { page: PAGE_TYPE.HOSPITAL });
        const admissionCards = _.find(res, { page: PAGE_TYPE.ADMISSION });
        const overallCards = _.find(res, { page: PAGE_TYPE.OVERALL });

        dispatch(setDeceasedCards(deceasedCards ? deceasedCards.cards : []));
        dispatch(
          setCommunityTransferCards(
            coTransfersCards ? coTransfersCards.cards : []
          )
        );
        dispatch(setHospitalCards(hospitalCards ? hospitalCards.cards : []));
        dispatch(setAdmissionCards(admissionCards ? admissionCards.cards : []));
        dispatch(setOverallCards(overallCards ? overallCards.cards : []));
      } else {
        dispatch(setDeceasedCards([]));
        dispatch(setCommunityTransferCards([]));
        dispatch(setHospitalCards([]));
        dispatch(setAdmissionCards([]));
        dispatch(setOverallCards([]));
      }
    });
  };

  useEffect(() => {
    if (selectedAccount) {
      getAllPercentageAgainst();
      getAllCustomCardFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  const accountRef = useRef();
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);

  const facilityRef = useRef();
  const [facilityAnchorEl, setFacilityAnchorEl] = useState(null);

  return (
    <div
      className={`df aic ${styles.hdr}`}
      style={{
        width: expandWidth,
      }}
    >
      <div
        className={`df aic ${styles.logoWrpr}`}
        style={{
          justifyContent: !expanded ? "center" : "space-between",
          width: expandWidth,
        }}
      >
        {expanded && <TempLogo />}
        <IconButton
          sx={{ height: "35px", width: "35px" }}
          onClick={() => {
            const root = document.querySelector(":root");
            if (!expanded) {
              root.style.setProperty("--sidebar-width", EXPAND_WIDTH);
              setExpandWidth(EXPAND_WIDTH);
              setExpanded(true);
            } else {
              root.style.setProperty("--sidebar-width", NOT_EXPAND_WIDTH);
              setExpanded(false);
              setExpandWidth(NOT_EXPAND_WIDTH);
            }
          }}
        >
          <div
            style={{
              transform: !expanded ? "rotate(180deg)" : "",
              transition: "width 0s ease-in",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ExpandArrowSvg />
          </div>
        </IconButton>
      </div>

      <div className={`df ffc ffmar fw5400 fs14 p-t-18 ${styles.linksWrpr}`}>
        <div
          className={`${styles.subHeader} ${!expanded ? styles.subHeaderTitle : null
            }`}
        >
          MAIN MENU
        </div>
        {permission && permission.role === AUTH_ROLES.USER
          ? permission.facilityAccess.write && (
            <NavWrpr
              strict
              to={setPreviousParams("/")}
              label={"Data Input"}
              expanded={expanded}
              icon={<DataInputNavSVG />}
            />
          )
          : (
            <NavWrpr
              strict
              to={setPreviousParams("/")}
              label={"Data Input"}
              expanded={expanded}
              icon={<DataInputNavSVG />}
            />
          )}
        {
          permission && permission.role === AUTH_ROLES.USER
            ? permission.facilityAccess.read &&
            suMenuItem.map((ele) => (
              <NavWrpr
                icon={ele?.icon}
                to={ele?.to}
                label={ele?.label}
                strict
                expanded={expanded}
                replace={ele?.replace}
              />
            ))
            : suMenuItem.map((ele) => (
              <NavWrpr
                icon={ele?.icon}
                to={`/${ele?.to}`}
                label={ele?.label}
                replace={ele?.replace}
                expanded={expanded}
                strict
              />
            ))
        }
      </div>

      <div className={`df ffc mta ffmm ${styles.usernameWrpr}`}>
        <div className={`${styles.selectionsWrpr}`}>
          <div
            className={!expanded ? styles.withoutExpandSelection : null}
            onClick={() => {
              if (accountAnchorEl) return;
              setAccountAnchorEl(accountRef?.current);
            }}
          >
            <div className={`${styles.subHeader} ${!expanded ? styles.withoutExpand : null}`}>COMPANY ACCOUNT</div>
            <AccountSelection
              expanded={expanded}
              account={account}
              accountRef={accountRef}
              setAccountAnchorEl={setAccountAnchorEl}
              accountAnchorEl={accountAnchorEl}
            />
          </div>
          <div
            onClick={() => {
              if (facilityAnchorEl) return;
              setFacilityAnchorEl(facilityRef?.current);
            }}
            className={`${!expanded ? styles.withoutExpandSelection : null} ${!expanded ? styles.withoutExpandSelectionFacility : null}`}>
            <div className={`${styles.subHeader} ${!expanded ? styles.withoutExpand : null}`}>FACILITIES</div>
            <FacilitySelection
              expanded={expanded}
              selectedFacility={selectedFacilities}
              facilityRef={facilityRef}
              facilityAnchorEl={facilityAnchorEl}
              setFacilityAnchorEl={setFacilityAnchorEl}
            />
          </div>
        </div>
        <div
          className={classNames(
            styles.subHeader,
            `${styles.subHeaderProfile} ${!expanded ? styles.withoutExpand : null
            }`
          )}
        >
          PROFILE
        </div>

        <div
          className={`df aic ${!expanded ? styles.usernameNotExpand : null} ${styles.username
            }`}
          ref={userOptionRef}
        >
          <Box
            component="div"
            className={styles.usernameInnerSection}
            onClick={handleClick}
            aria-controls={open ? "account-settings-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <div className={`${styles.userInfoWrapper} ${!expanded ? styles.expandedUserInfoWrapper : null}`}>
              <Avatar
                src={`/assets/avatars/${auth.avatar}.png`}
                alt={auth.fullName}
                className={`m-r-12`}
              />
              {expanded && (
                <div className={styles.userNameRole}>
                  <p className={`ffmar fw500`}>{auth.fullName}</p>
                  <div className={styles.role}>{roles[auth.type]}</div>
                </div>
              )}
            </div>
            {expanded && (
              <span
                className={classNames(
                  open && styles.active,
                  styles.selectMenuIcon
                )}
              >
                <AddNewSvg />
              </span>)}
          </Box>
        </div>

        <Menu
          anchorEl={anchorEl}
          id="account-settings-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 4,
            sx: {
              width: 250,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              ".MuiMenuItem-root": {
                fontFamily: "manrope",
                fontSize: "16px",
              },
              ".MuiMenuItem-root:hover": {
                background: "#2259c9",
                color: "#fff !important",
              },
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          width="240px"
        >
          <MenuItem component={Link} to={setPreviousParams("settings/profile")}>
            Manage profile
          </MenuItem>

          {checkMenuPermission(["super", "owner", "total"]) && (
            <MenuItem component={Link} to={setPreviousParams("settings/facilities")}>
              Manage facilities
            </MenuItem>
          )}

          {checkMenuPermission([
            "super",
            "owner",
            "total",
            "admin",
            "regional",
          ]) && (
              <MenuItem component={Link} to={setPreviousParams("settings/users")}>
                Manage users
              </MenuItem>
            )}
          {checkMenuPermission(["super"]) && (
            <MenuItem component={Link} to={setPreviousParams("settings/accounts")}>
              Manage accounts
            </MenuItem>
          )}
          {checkMenuPermission([
            "super",
            "owner",
            "total",
            "admin",
            "regional",
          ]) && (
              <MenuItem component={Link} to={setPreviousParams("logs")}>
                View timelogs
              </MenuItem>
            )}

          <Divider />

          <MenuItem onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
        {showAddAccount ? (
          <AddAccount close={() => setShowAddAccount(false)} />
        ) : undefined}
      </div>
    </div>
  );
};

export default Header;
