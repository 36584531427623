import * as React from "react";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	Switch,	
} from "@mui/material";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { ADD_NOTIFICATION } from "../../../store/types";
import { createAlertsReport, getAlertsReports } from "../../../services/alert-report.service";
import { LoadingButton } from "@mui/lab";

export const DEFAULT_CARD_FILTER = {
	hospitalizations: false,
	DCERData: false,
	insuranceData: false,
	returnsData: false,
	ninetyDaysData: false,
	doctorData: false,
	nurseData: false,
	floorsData: false,
	daysData: false,
	dxData: false,
	shiftData: false,
	hospitalData: false,
};

const FILTER_KEYS = Object.keys(DEFAULT_CARD_FILTER);

export default function AlertReport({ isAutomaticReportSaved, page }) {
	const [isOpen, setIsOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [initialValues, setInitialValues] = React.useState(
		FILTER_KEYS.reduce((acc, key) => ({ ...acc, [key]: false }), {})
	);
	const dispatch = useDispatch();

	const handleClose = React.useCallback(() => {
		setIsOpen(false);
	}, []);

	const getAlertsReportsDB = React.useCallback(async () => {
		setLoading(true);
		try {
			const res = await getAlertsReports({ page });
			if (res) {
				const databaseFilters = res?.alerts || {};
				const updatedInitialValues = FILTER_KEYS.reduce((acc, key) => {
					acc[key] = databaseFilters[key] !== undefined ? databaseFilters[key] : false;
					return acc;
				}, {});
				setInitialValues(updatedInitialValues);
			}
		} catch (error) {
			dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "error",
					label: "Failed to load alert settings",
					id: "loadSettingsError",
				},
			});
		} finally {
			setLoading(false);
		}
	}, [page, dispatch]);

	React.useEffect(() => {
		if (isOpen) {
			getAlertsReportsDB();
		}
	}, [isOpen, getAlertsReportsDB]);

	const handleSave = React.useCallback(
		async (valuesData) => {
			if (valuesData) {
				try {
					const updateData = {
						alerts: valuesData?.filters,
						page,
					};
					const res = await createAlertsReport(updateData);
					if (res) {
						dispatch({
							type: ADD_NOTIFICATION,
							payload: {
								type: "success",
								label: "Alert settings updated successfully",
								id: "alertSettingsUpdated",
							},
						});
					}
				} catch (error) {
					dispatch({
						type: ADD_NOTIFICATION,
						payload: {
							type: "error",
							label: "Failed to save alert settings",
							id: "alertSettingsSaveError",
						},
					});
				}
			}
		},
		[dispatch, page]
	);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			filters: initialValues,
			selectAll: false,
		},
		onSubmit: (values, { setSubmitting }) => {
			handleSave(values);
			setSubmitting(false); // Stop form submission after save
		},
	});

	const handleSelectAll = React.useCallback((event) => {
		const isChecked = event.target.checked;
		formik.setFieldValue('selectAll', isChecked);
		FILTER_KEYS.forEach((key) => {
			formik.setFieldValue(`filters.${key}`, isChecked);
		});
	}, [formik]);

	const formatLabel = (key) => {
		// Remove the word "Data" from the key
		let formattedKey = key.replace(/Data$/, '');
	  
		// Convert to camelCase by splitting words and capitalizing appropriately
		return formattedKey
		  .split(/(?=[A-Z])/) // Split when there's an uppercase letter
		  .map((word, index) =>
			word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize first letter of every word
		  )
		  .join(' '); // Join back with spaces
	  };
	  

	if (isAutomaticReportSaved) {
		return (
			<>
				<Box>
					<IconButton onClick={() => setIsOpen(true)} size="medium">
						<NotificationsActiveOutlinedIcon />
					</IconButton>
				</Box>
				{isOpen && (
					<Dialog fullWidth={true} maxWidth={"md"} open={isOpen} onClose={handleClose}>
						<DialogTitle>Hospital Alerts</DialogTitle>
						<DialogContent>
							{loading ? (
								<Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
									<CircularProgress />
								</Box>
							) : (
								<form onSubmit={formik.handleSubmit}>
									{/* "Select All" Checkbox */}
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={formik.values.selectAll}
													onChange={handleSelectAll}
												/>
											}
											label="Select all alerts"
										/>
									</FormGroup>

									{/* Grid of individual switches */}
									<Grid container spacing={2}>
										{FILTER_KEYS.map((key) => (
											<Grid item xs={6} sm={4} md={3} key={key}>
												<FormControlLabel
													control={
														<Switch
															name={`filters.${key}`}
															checked={formik.values.filters[key]}
															onChange={formik.handleChange}
														/>
													}
													label={formatLabel(key)}
												/>
											</Grid>
										))}
									</Grid>
								</form>
							)}
						</DialogContent>
						<DialogActions>
							<Box flex="1" display="flex" justifyContent="flex-end">
								<Button onClick={handleClose} variant="outlined">Close</Button>
								<LoadingButton
									type="submit"
									variant="contained"
									color="primary"
									onClick={formik.submitForm} // Trigger Formik submit on button click
									sx={{ marginLeft: 2 }}
									loading={formik.isSubmitting} // Show loading spinner when form is submitting
								>
									Save
								</LoadingButton>
							</Box>
						</DialogActions>
					</Dialog>
				)}
			</>
		);
	}

	return null;
}
